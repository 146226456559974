import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.scss';
import App from './app';

// ========================================

ReactDOM.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>,
    document.getElementById("root")
);
